import {useState, useEffect, useCallback, useMemo} from 'react';
import {ref, onValue, set} from 'firebase/database';
import {database} from '../utils/firebase';

export default function useFirebaseVal<T>(key: string): [T, (v: T) => void];
export default function useFirebaseVal<T>(
  key: string | null,
  prefix: string
): [T, (v: T) => void];

export default function useFirebaseVal<T>(key?: string, prefix: string = '') {
  const [value, setValue] = useState<T>(null);

  const refkey = useMemo(() => `${prefix}${key}`, [prefix, key]);

  useEffect(() => {
    if (!key) {
      return;
    }

    const databaseRef = ref(database, refkey);
    const unsub = onValue(databaseRef, (snapshot) => setValue(snapshot.val()));

    return () => unsub();
  }, [refkey, key]);

  const setRemoteValue = useCallback(
    (value) => set(ref(database, refkey), value),
    [refkey]
  );

  return [value, setRemoteValue];
}
