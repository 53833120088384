import {useCallback, useState} from 'react';
import PermissionsHowTo from '../Lobby/PermissionsHowTo';
import RequestPermissions from '../Lobby/RequestPermissions';

interface PermssionsSetuProps {
  camPermission: boolean | null;
  micPermission: boolean | null;
  setPermissions: () => Promise<void>;
}

const PermissionsSetup = ({
  camPermission,
  micPermission,
  setPermissions,
}: PermssionsSetuProps) => {
  const [permissionAsked, setPermissionAsked] = useState(false);
  const permissionGranted = camPermission && micPermission;

  const handlePermissionAsked = useCallback(async () => {
    await setPermissions();
    setPermissionAsked(true);
  }, [setPermissions]);

  if (!permissionAsked) {
    return (
      <RequestPermissions
        missingPermissions="both"
        setPermissions={handlePermissionAsked}
      />
    );
  }

  if (!permissionGranted) {
    return <PermissionsHowTo type="both" />;
  }

  return null;
};

export default PermissionsSetup;
