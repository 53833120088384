import React from 'react';
import styled from 'styled-components';
import {LoaderMarbles} from '../../Loader';
import {WaitingStatus} from '../../../types/video-room';

interface WaitingStatusProps {
  status: WaitingStatus;
}

const WaitingStatusBox = ({status}: WaitingStatusProps) => {
  return (
    <Status>
      {status === 'waiting' && <LoaderMarbles />}
      <StatusMessage $status={status}>
        {WaitingStatusMessage(status)}
      </StatusMessage>
    </Status>
  );
};

export default WaitingStatusBox;

function WaitingStatusMessage(status) {
  switch (status) {
    case 'busy':
      return "Al momento l'operatore non è disponibile";
    case 'ready':
      return 'Tutto pronto';
    case 'not-available':
      return "Al momento l'operatore non è disponibile";
    case 'waiting':
    default:
      return 'In attesa di risposta';
  }
}

const Status = styled.div`
  margin-top: 30px;
`;

const StatusMessage = styled.span<{$status: WaitingStatus}>`
  display: block;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => {
    switch (props.$status) {
      case 'busy':
        return props.theme.colors.warning;
      case 'ready':
        return props.theme.colors.success;
      case 'not-available':
        return props.theme.colors.error;
      case 'waiting':
      default:
        return props.theme.colors.loading;
    }
  }};

  @media ${(props) => props.theme.responsive.phone} {
    font-size: 17px;
  }
`;
