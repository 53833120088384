import React, {useEffect, useRef, useState} from 'react';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {useUser} from '../../contexts/UserContext';
import {updateText} from '../../utils/api';
import ToastMessage from '../Admin/ToastMessage';
import {IconCheck, IconClose, IconEdit} from '../Icons';

type TextEditableProps = {
  roomId: string;
  langKey: string;
  children: React.ReactElement<{
    contentEditable: boolean;
    ref?: React.MutableRefObject<HTMLElement | undefined>;
    onClick?: () => void;
  }>;
};

const TextEditable = ({roomId, langKey, children}: TextEditableProps) => {
  const {user} = useUser();
  const [edit, setEdit] = useState(false);
  const {
    i18n: {language},
  } = useTranslation();

  const elementRef = useRef<HTMLElement>();

  const handleSave = async () => {
    setEdit(false);
    // console.log(elementRef.current?.textContent);
    // TODO: manage errors?
    if (elementRef.current?.textContent) {
      await updateText(
        language,
        roomId,
        langKey,
        elementRef.current?.textContent
      );
      toast.custom((t) => (
        <ToastMessage
          t={t}
          subtitle="Modifica testo"
          message="Salvataggio avvenuto correttamente"
          type="success"
        />
      ));
    }
  };

  // when enter editing mode, focus the element
  useEffect(() => {
    if (edit) {
      elementRef.current?.focus();
    }
  }, [edit]);

  // when key pressing, if is Enter save and preventDefault
  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    const element = elementRef.current;

    const handleEnter = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        handleSave();
      }
    };

    element.addEventListener('keypress', (e) => handleEnter(e));

    return () => {
      element.removeEventListener('keypress', (e) => handleEnter(e));
    };
  }, [elementRef]);

  const childrenElement = React.Children.only(children);

  if (!childrenElement || !React.isValidElement(childrenElement)) {
    throw new Error('Invalid children for TextEditable');
  }

  if (!user || user?.isGuest) {
    return children;
  }

  const actionsTpl = edit ? (
    <>
      <Button onClick={() => setEdit(false)} title="Annulla" role="undo">
        <IconClose fill="#fff" size="18px" />
      </Button>
      <Button onClick={() => handleSave()} title="Salva" role="confirm">
        <IconCheck fill="#fff" size="18px" />
      </Button>
    </>
  ) : (
    <Button onClick={() => setEdit(true)} title="Clicca e modifica il testo">
      <IconEdit size="18px" />
    </Button>
  );

  const element = React.cloneElement(childrenElement, {
    contentEditable: edit,
    ref: elementRef,
    onClick: !edit ? () => setEdit(true) : undefined,
  });

  return (
    <Editor isEditing={edit}>
      <Actions>{actionsTpl}</Actions>
      {element}
    </Editor>
  );
};

export default TextEditable;

const Editor = styled.div<{isEditing?: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > :not(:first-child) {
    background-color: #efefef;
    border-radius: 5px;
  }

  ${(props) =>
    props.isEditing &&
    css`
      cursor: text;
      &:hover > :not(:first-child) {
        background-color: transparent;
      }
    `}
`;

const Actions = styled.div`
  display: flex;
  margin-right: 8px;
  padding-bottom: 8px;
`;

const Button = styled.button<{role?: string}>`
  appearance: none;
  padding: 3px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;

  & + & {
    margin-left: 4px;
  }

  ${(props) =>
    props.role === 'confirm' &&
    css`
      background-color: ${(props) => props.theme.colors.success};
    `}
  ${(props) =>
    props.role === 'undo' &&
    css`
      background-color: ${(props) => props.theme.colors.error};
    `}
`;
