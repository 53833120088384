import {useCallback, useEffect, useRef} from 'react';

export default function useLocalTracksSetup(
  cameraPermissionGranted: boolean | null,
  microphonePermissionGranted: boolean | null,
  getAudioVideoTracks: () => Promise<void>
) {
  const permissionGranted =
    cameraPermissionGranted && microphonePermissionGranted;
  const autoStartEnabled = useRef(true);

  const startAudioVideo = useCallback(async () => {
    try {
      await getAudioVideoTracks();
    } catch (e) {
      console.log(e);
    }
  }, [getAudioVideoTracks]);

  const handleStartAudioVideo = useCallback(async () => {
    await startAudioVideo();
    autoStartEnabled.current = false;
  }, [startAudioVideo]);

  // if we already have the permissions at first render
  // or when permissions changes (they could be null on first render) beacuse we have them without asking, startAudioVideo
  useEffect(() => {
    if (
      cameraPermissionGranted === true &&
      microphonePermissionGranted === true &&
      autoStartEnabled.current
    ) {
      startAudioVideo();
    }
  }, [cameraPermissionGranted, microphonePermissionGranted]);

  return {
    permissionGranted,
    handleStartAudioVideo,
  };
}
