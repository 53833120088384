import {useRouter} from 'next/router';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {format} from 'date-fns';
import LocalMediaSetup from '../../Video/LocalMediaSetup';
import {HeadingM, Subtitle} from '../../Typography';
import {Card} from '../Boxes/layout';
import WaitingStatusBox from '../WaitingStatusBox';
import FlashMessage from '../../FlashMessages';
import MobileMessage from '../../FlashMessages/MobileMessage';
import useRoomWaiting from '../../../hook/useRoomWaiting';
import {joinRoomFromRequest} from '../../../utils/api';
import jingle from '../../../assets/notification.mp3';
import {useTranslation} from 'react-i18next';
import TextEditable from '../../TextEditable';
import {
  useAudioDevices,
  useLocalTracks,
  useVideoDevices,
} from 'youmain-videoroom-lib';
import PermissionsSetup from '../../Video/PermissionsSetup';
import useLocalTracksSetup from '../../../hook/useLocalTracksSetup';
import {sendCustomEvent} from '../../../utils/analytics';

// TODO: refactoring this with LiveFlow / WaitingStep.
type WaitingStepProps = {
  store: Store;
  requestId: string;
  referralFlow?: 'appointment' | 'directAccess' | 'live';
};

export default function WaitingStep({
  store,
  requestId,
  referralFlow = 'live',
}: WaitingStepProps) {
  const {
    cameraPermissionGranted,
    microphonePermissionGranted,
    video,
    audio,
    videoSpecs,
    publishCamera,
    unpublishCamera,
    publishMicrophone,
    unpublishMicrophone,
    getAudioVideoTracks,
    toggleBlur,
    blurEnabled,
  } = useLocalTracks();

  // handle or autoStart audio/video based on permissions
  const {permissionGranted, handleStartAudioVideo} = useLocalTracksSetup(
    cameraPermissionGranted,
    microphonePermissionGranted,
    getAudioVideoTracks
  );

  const audioDevices = useAudioDevices();
  const videoDevices = useVideoDevices();

  const router = useRouter();
  const [isEntering, setIsEntering] = useState(false);

  const roomWaitingState = useRoomWaiting(requestId);
  const audioRef = useRef(new Audio(jingle));
  const {t} = useTranslation();

  const handleSubmit = useCallback(async () => {
    setIsEntering(true);
    const {twilioToken} = await joinRoomFromRequest(roomWaitingState.id);
    setIsEntering(false);

    sendCustomEvent('flowInteraction', 'startCall', referralFlow);

    router.push(
      {
        pathname: '/_call',
        query: {
          store: store.slug,
          room: roomWaitingState.room,
          storeId: store.id,
          token: twilioToken,
        },
      },
      router.asPath
    );
  }, [store, roomWaitingState, router]);

  const handleReservation = useCallback(() => {
    router.push(
      {
        pathname: '/[store]/reservation',
        query: {
          store: store.slug,
        },
      },
      `/${store.slug}/reservation`
    );
  }, [store.slug, router]);

  // listen for changes on roomWaiting state
  useEffect(() => {
    if (roomWaitingState.status === 'ready' && permissionGranted) {
      audioRef.current.play();
      handleSubmit();
    }
  }, [roomWaitingState, permissionGranted, handleSubmit]);

  if (!permissionGranted) {
    return (
      <Card>
        <PermissionsSetup
          camPermission={cameraPermissionGranted}
          micPermission={microphonePermissionGranted}
          setPermissions={handleStartAudioVideo}
        />
      </Card>
    );
  }

  return (
    <>
      <Card>
        <TextEditable roomId={store.rooms[0].id} langKey={'live-flow-title'}>
          <HeadingM>{t('live-flow-title', {ns: store.rooms[0].id})}</HeadingM>
        </TextEditable>
        <TextEditable
          roomId={store.rooms[0].id}
          langKey={'live-flow-waiting-step-text'}
        >
          <Subtitle>
            {t('live-flow-waiting-step-text', {ns: store.rooms[0].id})}
          </Subtitle>
        </TextEditable>

        <LocalMediaSetup
          videoTrack={video}
          audioTrack={audio}
          videoSpecs={videoSpecs}
          openMic={publishMicrophone}
          closeMic={unpublishMicrophone}
          openCamera={publishCamera}
          closeCamera={unpublishCamera}
          videoDevices={videoDevices}
          audioDevices={audioDevices}
          selectedVideoDevice={video?.enabled ? video?.label : ''}
          selectedAudioDevice={audio?.enabled ? audio?.label : ''}
          toggleBlur={toggleBlur}
          blurEnabled={blurEnabled}
        />

        {permissionGranted && (
          <WaitingStatusBox status={roomWaitingState.status} />
        )}
      </Card>

      {permissionGranted && (
        <>
          <FlashMessageByStatus
            state={roomWaitingState}
            loading={isEntering}
            onJoin={handleSubmit}
            onCancel={handleReservation}
          />
          <MobileMessage status={roomWaitingState.status} />
        </>
      )}
    </>
  );
}

const FlashMessageByStatus = ({
  state,
  loading,
  onCancel,
  onJoin,
}: {
  state: RoomWaitingState;
  loading: boolean;
  onCancel: () => void;
  onJoin: () => void;
}) => {
  let internalMessages = [];
  if (state.message) {
    internalMessages = [
      {
        text: state.message,
        time: format(new Date(state.updatedAt), 'HH:mm'),
      },
    ];
  }

  if (state.status === 'waiting') {
    return (
      <FlashMessage
        status="loading"
        title="La tua richiesta d'accesso è stata inviata."
        subtitle="Ti preghiamo di attendere."
        messages={internalMessages}
        solid
      />
    );
  }

  if (state.status === 'not-available' || state.status === 'expired') {
    return (
      <FlashMessage
        status="error"
        title="Al momento non è possibile partecipare."
        subtitle="Puoi provare a prenotare un appuntamento."
        messages={internalMessages}
        button={{
          title: 'Prenota un appuntamento',
          onClick: onCancel,
        }}
        solid
      />
    );
  }

  if (state.status === 'busy') {
    return (
      <FlashMessage
        status="warning"
        title="Al momento non è possibile partecipare."
        subtitle="Ti preghiamo di attendere."
        messages={internalMessages}
        solid
      />
    );
  }

  if (state.status === 'ready') {
    return (
      <FlashMessage
        status="success"
        title="Tutto pronto!"
        subtitle="Stai per accedere alla stanza."
        messages={internalMessages}
        button={{
          title: 'Entra',
          onClick: onJoin,
          loading: loading,
        }}
        solid
      />
    );
  }

  return null;
};
