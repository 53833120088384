import useFirebaseVal from './useFirebaseVal';

export default function useRoomWaiting(requestKey: string): RoomWaitingState {
  const [request] = useFirebaseVal<RoomWaitingState>(requestKey, 'requests/');

  if (request) {
    return request;
  }

  return {
    id: null,
    status: 'waiting',
    room: '',
    name: '',
    createdAt: '',
    updatedAt: '',
  };
}
