import React from 'react';
import styled, {css} from 'styled-components';
import {TextS} from '../Typography';
import {WaitingStatus} from '../../types/video-room';

interface MobileMessageType {
  status: WaitingStatus;
}

const MobileMessage = ({status}: MobileMessageType) => {
  return (
    <MobileMessageContainer $status={status}>
      <TextS>
        Non uscire dal browser cosi potremo avvisarti appena l’operatore
        accetterà la tua richiesta.
      </TextS>
    </MobileMessageContainer>
  );
};

export default MobileMessage;

const MobileMessageContainer = styled.div<{$status: WaitingStatus}>`
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  margin-top: 12px;
  border-radius: 5px;
  text-align: center;
  padding: 12px;

  ${TextS} {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
  }

  @media ${(props) => props.theme.responsive.tablet} {
    ${(props) =>
      props.$status === 'waiting' &&
      css`
        display: flex;
      `}
  }
`;
