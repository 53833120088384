import React from 'react';
import styled, {keyframes} from 'styled-components';

export const LoaderMarbles = () => {
  return (
    <Container>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </Container>
  );
};

const bounce = keyframes`
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
`;
const bounceInvert = keyframes`
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.loading};
    animation: ${bounce} linear 2s infinite;

    & + span {
      margin-left: 8px;
    }

    &:nth-child(odd) {
      animation-name: ${bounceInvert};
    }
  }
`;
