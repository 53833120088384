import React, {useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {lighten} from 'polished';
import Button from '../Buttons/Button';
import {Text} from '../Typography';

type StatusType = 'loading' | 'success' | 'warning' | 'error';

interface FlashInternalMessage {
  text: string;
  time: string;
}

interface FlashMessageType {
  title: string;
  subtitle?: string;
  status: StatusType;
  icon?: React.ComponentType;

  messages?: FlashInternalMessage[];

  onClick?: () => void;

  // button props
  button?: {};
  full?: boolean;
  solid?: boolean;
}

const FlashMessage = ({
  title,
  subtitle,
  icon: IconComp,
  status,
  messages = [],
  button,
  onClick,
  full,
  solid,
}: FlashMessageType) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref]);

  return (
    <Container
      $status={status}
      full={full}
      onClick={onClick}
      solid={solid}
      ref={ref}
    >
      {IconComp && (
        <Icon>
          <IconComp fill="#fff" />
        </Icon>
      )}
      <Content>
        <Title>{title}</Title>
        {subtitle && <Body>{subtitle}</Body>}
        {messages.map((message, i) => (
          <OperatorMessage key={i}>
            <Message>{message.text}</Message>
            <Time>{message.time}</Time>
          </OperatorMessage>
        ))}

        {button && (
          <StyledButton success={status === 'success'} full {...button} />
        )}
      </Content>
    </Container>
  );
};

export default FlashMessage;

const Container = styled.div<{
  $status: StatusType;
  full?: boolean;
  solid?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 16px;
  padding-left: 24px;
  border-radius: 5px;
  margin: 15px 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  border-left: none;
  position: relative;
  color: ${(props) => {
    switch (props.$status) {
      case 'warning':
        return props.theme.colors.warning;
      case 'success':
        return props.theme.colors.success;
      case 'error':
        return props.theme.colors.error;
      case 'loading':
      default:
        return props.theme.colors.info;
    }
  }};

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px 0 0 5px;
    background-color: ${(props) => {
      switch (props.$status) {
        case 'warning':
          return props.theme.colors.warning;
        case 'success':
          return props.theme.colors.success;
        case 'error':
          return props.theme.colors.error;
        case 'loading':
        default:
          return props.theme.colors.info;
      }
    }};
  }

  svg {
    g[stroke] {
      stroke: ${(props) => {
        switch (props.$status) {
          case 'warning':
            return props.theme.colors.warning;
          case 'success':
            return props.theme.colors.success;
          case 'error':
            return props.theme.colors.error;
          case 'loading':
          default:
            return props.theme.colors.info;
        }
      }};
    }
  }

  ${(props) =>
    props.solid &&
    css`
      color: #fff;
      border: none;
      background-color: ${() => {
        switch (props.$status) {
          case 'warning':
            return props.theme.colors.warning;
          case 'success':
            return props.theme.colors.success;
          case 'error':
            return props.theme.colors.error;
          case 'loading':
          default:
            return props.theme.colors.info;
        }
      }};

      > div > p {
        color: #fff;
      }
    `}

  & + & {
    margin-top: 24px;
  }

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  margin: 0;
`;

const Body = styled(Text)`
  margin: 8px 0 0 0;
  @media ${(props) => props.theme.responsive.phone} {
    font-size: 15px;
    margin: 4px 0 0 0;
  }
`;

const Icon = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OperatorMessage = styled.div`
  position: relative;
  margin-top: 24px;
  padding: 16px;
  border-radius: 6px;
  background-color: white;
  font-family: ${(props) => props.theme.typography.main};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -18px;
    width: 0;
    height: 0;
    border: solid 20px transparent;
    border-top-color: white;
  }

  @media ${(props) => props.theme.responsive.phone} {
    margin-top: 16px;
  }
`;

const Message = styled(Text)`
  margin: 0 0 4px;
  color: ${(props) => props.theme.colors.label};
`;

const Time = styled.span`
  font-size: 12px;
  font-style: italic;
  color: ${(props) => props.theme.colors.label};
  opacity: 0.8;
`;

const StyledButton = styled(Button)`
  display: block;
  margin-top: 24px;
  background-color: white;
  color: ${(props) =>
    props.success ? props.theme.colors.success : props.theme.colors.error};

  ${(props) =>
    props.loading &&
    css`
      &::after {
        border-color: ${(props) => props.theme.colors.success};
        border-bottom-color: white;
      }
    `}

  @media (hover: hover) {
    &:hover:hover,
    &:focus:focus {
      background-color: white;
    }
  }
`;
