import React from 'react';
import styled, {css} from 'styled-components';
import {Toast, toast} from 'react-hot-toast';
import {enter, exit} from '../../Animations';
import {IconClose} from '../../Icons';

interface ToastProps {
  t: Toast;
  subtitle?: string;
  message: string;
  type?: 'success' | 'error' | 'warning' | 'default';
  showClose?: boolean;
  onClick?: () => void;
}

const ToastMessage = ({
  t,
  subtitle,
  message,
  type = 'default',
  showClose,
  onClick,
}: ToastProps) => {
  return (
    <Container
      type={type}
      animation={t.visible ? 'in' : 'out'}
      onClick={onClick}
    >
      <Content>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Message>{message}</Message>
      </Content>
      {showClose && (
        <Close onClick={() => toast.dismiss(t.id)} title="Chiudi">
          <IconClose fill="#fff" />
        </Close>
      )}
    </Container>
  );
};

export default ToastMessage;

const Close = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  font-family: ${(props) => props.theme.typography.main};
  padding: 16px;
  cursor: pointer;
  transition: background-color 400ms;
`;

const Container = styled.div<{
  type?: 'success' | 'error' | 'warning' | 'default';
  animation?: 'in' | 'out';
}>`
  display: flex;
  background-color: ${(props) => props.theme.colors.info};
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  cursor: pointer;

  ${(props) =>
    props.type === 'success' &&
    css`
      background-color: ${(props) => props.theme.colors.success};
    `}
  ${(props) =>
    props.type === 'error' &&
    css`
      background-color: ${(props) => props.theme.colors.error};
    `}
  ${(props) =>
    props.type === 'warning' &&
    css`
      background-color: ${(props) => props.theme.colors.warning};
    `}

  ${(props) =>
    props.animation === 'in' &&
    css`
      animation: ${enter} 0.2s ease-in-out forwards;
    `}
  ${(props) =>
    props.animation === 'out' &&
    css`
      animation: ${exit} 0.2s ease-in-out forwards;
    `}

  &:hover {
    ${Close} {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;
const Content = styled.div`
  padding: 16px;
`;
const Subtitle = styled.h3`
  margin: 0 0 6px 0;
  font-size: 14px;
  font-weight: 400;
`;
const Message = styled.p`
  margin: 0;
  font-weight: 700;
`;
